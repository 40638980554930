<template>
  <div class="export-container">
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报名人管理</el-breadcrumb-item>
      <el-breadcrumb-item>报名人导出</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索栏 -->
    <el-card class="filter-card">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="搜索活动名称"
            v-model="searchQuery.title_cn"
            clearable
            size="medium"
            @clear="fetchActivities()"
            @keyup.enter.native="fetchActivities()">
            <el-button slot="append" icon="el-icon-search" @click="fetchActivities()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.type" size="medium" placeholder="活动类型" @change="fetchActivities()">
            <el-option
              v-for="(name, id) in activityTypes"
              :key="id"
              :label="name"
              :value="Number(id)">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.status" size="medium" placeholder="活动状态" @change="fetchActivities()">
            <el-option
              v-for="(item, id) in statusMap"
              :key="id"
              :label="item.text"
              :value="Number(id)">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="default" size="medium" @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </el-card>

    <!-- 活动卡片网格 -->
    <div class="activities-grid">
      <el-card
        v-for="activity in activityList"
        :key="activity.activity_id"
        class="activity-card"
        :class="{ 'activity-card-ended': activity.status === 3 }">
        
        <div class="activity-header">
          <span class="activity-title">{{ activity.title_cn }}</span>
          <el-tag :type="getStatusType(activity.status)" size="small">
            {{ getStatusText(activity.status) }}
          </el-tag>
        </div>

        <div class="activity-type">
          <i class="el-icon-collection-tag"></i>
          {{ getActivityType(activity.type) }}
        </div>

        <div class="activity-dates">
          <i class="el-icon-date"></i>
          {{ activity.start_date }} 至 {{ activity.end_date }}
        </div>

        <div class="participants-info">
          <el-progress
            :percentage="getParticipantPercentage(activity)"
            :status="getProgressStatus(activity)"
            :format="getProgressFormat">
          </el-progress>
        </div>

        <div class="activity-actions">
          <div class="participants-count">
            <span class="current">{{ activity.current_participants || 0 }}</span>
            <span class="separator">/</span>
            <span class="max">{{ activity.max_participants || 0 }}</span>
            <span class="label">人</span>
          </div>
          <el-button type="primary" size="small" @click="exportExcel(activity.activity_id)">
            <i class="el-icon-download" v-if="!activity.exporting"></i>
            <i class="el-icon-loading" v-else></i>
            导出名单
          </el-button>
        </div>
      </el-card>
    </div>

    <!-- 分页 -->
    <div class="pagination-container" v-if="total > 0">
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Export',
  data() {
    return {
      activityList: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
      searchQuery: {
        title_cn: '',
        type: null,
        status: null
      },
      activityTypes: {
        1: '内地研学',
        2: '疆内研学',
        3: '主题研学',
        4: '订制团'
      },
      statusMap: {
        1: { text: '未开始', type: 'success' },
        2: { text: '进行中', type: 'warning' },
        3: { text: '已结束', type: 'info' }
      }
    }
  },
  created() {
    this.fetchActivities()
  },
  methods: {
    fetchActivities() {
      this.$http({
        method: 'get',
        url: 'activities/search',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.activityList = res.data.map(activity => ({
          ...activity,
          exporting: false
        }))
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '获取活动列表失败')
      })
    },

    resetSearch() {
      this.searchQuery = {
        title_cn: '',
        type: null,
        status: null
      }
      this.fetchActivities()
    },

    getActivityType(type) {
      return this.activityTypes[type] || '未知类型'
    },

    getStatusText(status) {
      return this.statusMap[status]?.text || '未知状态'
    },

    getStatusType(status) {
      return this.statusMap[status]?.type || ''
    },

    getParticipantPercentage(activity) {
      if (!activity.max_participants) return 0
      return Math.round((activity.current_participants / activity.max_participants) * 100) || 0
    },

    getProgressStatus(activity) {
      const percentage = this.getParticipantPercentage(activity)
      if (percentage >= 100) return 'success'
      if (percentage >= 80) return 'warning'
      return null
    },
    
    getProgressFormat(percentage) {
        return `${percentage}%`
    },

    // 导出报名人名单
    exportExcel(activityId) {
      const activity = this.activityList.find(a => a.activity_id === activityId)
      if (!activity) return

      activity.exporting = true
      this.$http.get(`/participants/export/excel/${activityId}`, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = `《${activity.title_cn}》报名人名单_${Date.now().toString().substring(12, 8)}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(err => {
        this.$message.error(err.response?.data?.message || '导出失败')
      }).finally(() => {
        activity.exporting = false
      })
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchActivities()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchActivities()
    }
  }
}
</script>

<style scoped>
.export-container {
  padding: 0px;
}

.filter-card {
  padding: 0px;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.activity-card {
  position: relative;
  overflow: hidden;
}

.activity-card-ended {
  opacity: 0.8;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.activity-title {
  font-size: 15px;
  font-weight: 500;
  color: #303133;
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity-type,
.activity-dates {
  color: #606266;
  margin: 8px 0;
  font-size: 12px;
}

.activity-type i,
.activity-dates i {
  margin-right: 5px;
}

.participants-info {
  margin: 15px 0;
}

.activity-actions {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.participants-count {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  color: #909399;
}

.participants-count .current {
  font-size: 16px;
  font-weight: 500;
  color: #409EFF;
}

.participants-count .separator {
  margin: 0 3px;
}

.participants-count .label {
  margin-left: 3px;
}

.pagination-container {
  margin-top: 30px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 10px 15px;
  background-color: #fff;
}

.export-container :deep(.el-progress-bar__inner) {
  transition: all 0.3s ease;
}

.export-container :deep(.el-progress-bar__outer) {
  background-color: #f0f2f5;
}

.export-container :deep(.el-progress__text) {
  font-size: 12px !important;
}
</style>
